<template lang="pug">
  div
    base-navigation-tabs(
      :items="links"
    )
    v-divider
    v-container
      router-view
</template>
<script>
export default {
  name: 'FeedingPrograms',
  data: () => ({
    links: [
      { label: 'Manage', path: { name: 'stocks.feeding-programs' } },
      { label: 'Categories', path: { name: 'stocks.feeding-programs.categories' } },
    ],
  }),
}
</script>